import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import dragDrop from "./reducers/dragDrop";
import storage from "redux-persist/lib/storage";
import cartReducer from "./reducers/cart";
import company from "./reducers/company";
import menuReducer from "./reducers/menu";
import userReducer from "./reducers/user";
import returntab from "./reducers/returntab";
import PaymentId from "./reducers/selectedpayment";
import theme from "./reducers/theme";
import temporyOrder from "./reducers/temporyOrder";
import orderLocationInfo from "./reducers/orderLocationInfo";
import MoveOrder from "./reducers/moveOrder";
import hotelCompanyInfo from "./reducers/hotelCompanyinfo";
import cfPopUpAction from "./reducers/cfpopupaction/cfPopUpAction";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import order from "./reducers/order";
import display from "./reducers/display";
import Drawer from "./reducers/Drawer";
import adminOrder from "./reducers/adminOrder";
import Tables from "./reducers/table";
import kds from "./reducers/kds";
import sidebar from "./reducers/sidebar";
import translations from "./reducers/translations";
import RootApi from "./services/RootApi";
import customers from "./reducers/customers";
import logs from "./reducers/logs";
import expireSlice from "./reducers/expireSlice";
import orderLanguageInfo from "./reducers/orderLanguageInfo";
import ServiceOrders from "./reducers/serviceOrder";
import HotelServiceSlice from "./reducers/hotelService";
import PrinterSlice from "./reducers/printer";
import drawerDataPassProps from "./reducers/drawerDataPassProps";
import deleteContactSlice from "./reducers/deleteContact";
import onChosenorde from "./reducers/onChoseOrder";
import PromotionsSlice from "./reducers/promotions";
import selectedStatusSlice from "./reducers/selectedStatus";
import NotificationSlice from "./reducers/notifications";
import HotelPosSlice from "./reducers/hotelpos";
import FacilitiesCategoriesSlice from "./reducers/facilities/facilities_categories";
import FacilitiesItemsSlice from "./reducers/facilities/facilities_items";
import staffSlice from "./reducers/staffSlice";
import PosSlice from "./reducers/pos/pos";
import StaffSlice from "./reducers/staff";
import GeofunceSlice from "./reducers/geofunce";
import MenuCategorieSlice from "./reducers/menuCategories";
import PopUpReducer from "./reducers/popupReducer";
import CfPopUpReducer from "./reducers/cfPopUp";
import UpsellReducer from "./reducers/upsellReducer";
import roomServiceChart from "./reducers/roomserviceChart";
import feedbackBackofficeSlice from "./reducers/feedbackData";
import staffMenuSlice from "./reducers/staffMenuSlice";
import spaBooking from "./reducers/spaBooking";

const reducer = combineReducers({
  cart: cartReducer,
  menu: menuReducer,
  user: userReducer,
  company: company,
  returnTab: returntab,
  PaymentId: PaymentId,
  theme: theme,
  temporyOrder: temporyOrder,
  hotelCompanyInfo: hotelCompanyInfo,
  orderLocationInfo: orderLocationInfo,
  orderLanguageInfo: orderLanguageInfo,
  dragDrop: dragDrop,
  orders: order,
  MoveOrder: MoveOrder,
  display: display,
  Drawer: Drawer,
  Tables: Tables,
  adminOrder: adminOrder,
  kds: kds,
  drawerDataPassProps: drawerDataPassProps,
  sidebar: sidebar,
  translations: translations,
  customers: customers,
  logs: logs,
  expire: expireSlice,
  ServiceOrders: ServiceOrders,
  Printer: PrinterSlice,
  hotelService: HotelServiceSlice,
  deleteContactSlice: deleteContactSlice,
  onChosenorde: onChosenorde,
  PromotionsSlice: PromotionsSlice,
  selectedStatusSlice: selectedStatusSlice,
  NotificationSlice: NotificationSlice,
  HotelPosSlice: HotelPosSlice,
  FacilitiesCategoriesSlice: FacilitiesCategoriesSlice,
  FacilitiesItemsSlice: FacilitiesItemsSlice,
  cfPopUpAction: cfPopUpAction,
  Staffs: staffSlice,
  staff: StaffSlice,
  Pos: PosSlice,
  GeofunceSlice: GeofunceSlice,
  MenuCategorieSlice: MenuCategorieSlice,
  PopUpReducer: PopUpReducer,
  CfPopUpReducer: CfPopUpReducer,
  UpsellReducer: UpsellReducer,
  roomServiceChart: roomServiceChart,
  feedbackBackofficeSlice: feedbackBackofficeSlice,
  staffMenuSlice: staffMenuSlice,
  spaBooking: spaBooking,
  [RootApi.reducerPath]: RootApi.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "user",
    "company",
    "theme",
    "orderLocationInfo",
    "display",
    "staff",
    "hotelCompanyInfo",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(RootApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
