import { createSlice } from "@reduxjs/toolkit";

export interface SpaBookingType {
  spaService: SpaService | null;
  spaCategories: SpaCategory[];
}

const initialState: SpaBookingType = {
  spaService: null,
  spaCategories: [],
};

const spaBooking = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    insertSpaService: (state, action) => {
      state.spaService = action.payload;
    },
    insertSpaCategories: (state, action) => {
      state.spaCategories = action.payload;
    },
    addSpaCategories: (state, action) => {
      state.spaCategories = [...state.spaCategories, action.payload];
    },
  },
});

export const { insertSpaService, insertSpaCategories, addSpaCategories } =
  spaBooking.actions;

export const getSpaServicesData: any = (state: any) =>
  state.spaBooking.spaService;

export const getSpaCategoriesData = (state: any) =>
  state.spaBooking.spaCategories;

export default spaBooking.reducer;
